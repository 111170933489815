<template>
  <octo-table
    show-filters
    :action="endpoints.DATATABLES.callCenterLeads"
    :fields="fields"
    :filters="filters"
    :name="name"
    :order-by-items="['deadline', 'date_in']"
    @onResetFilters="filters = {}"
  >
    <template v-slot:name="data">
      <div class="text-truncate">
        {{ data.row | optional('lead.registry.surname') }}
        {{ data.row | optional('lead.registry.name') }}
      </div>
      <div class="text-uppercase small">{{ $t('common.campaign') }}:
        <label-theme-component>{{ data.row.campaign.name }}</label-theme-component>
      </div>
    </template>

    <template v-slot:callCenterLeadStatus="data">
      <badge :type="data.row.status | callCenterLeadStatusColor" class="text-uppercase">
        {{ $t('callcenter.status.' + data.row.status) }}
      </badge>
      <div v-if="data.row.deadline" class="small">{{ data.row.deadline | datetime }}</div>
    </template>

    <template v-slot:date="data">
      {{ data.row.lead.date_in | date }}
    </template>

    <template v-slot:operator="data">
      <div class="text-truncate text-uppercase">
        {{ users[data.row.user_id] | userCode }}
      </div>
    </template>

    <template v-slot:actions="data">
      <div class="d-flex justify-content-end">
        <el-tooltip :content="$t('common.info')"
                    :open-delay="300"
                    placement="top">
          <base-button
            class="mx-1"
            size="sm"
            icon
            link
            @click="$router.push({name: 'leads.in_progress.show', params: {id : data.row.id }})"
          >
            <octo-icon icon="right-arrow"/>
          </base-button>
        </el-tooltip>
      </div>
    </template>

    <template slot="custom-filters">
      <div class="col-12 col-md-6">
        <base-input :label="$t('datatable.operators')">
          <el-select
            multiple
            filterable
            class="select-default text-uppercase"
            :placeholder="$t('common.type_to_search')"
            v-model="filters.operatorIds"
          >
            <el-option
              v-for="option in operatorsOptions"
              class="select-default text-uppercase"
              :value="option.value"
              :label="option.label"
              :key="option.value"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-12 col-md-6">
        <base-input :label="$t('datatable.campaign')">
          <el-select
            multiple
            filterable
            class="select-default text-uppercase"
            :placeholder="$t('common.type_to_search')"
            v-model="filters.campaignIds"
          >
            <el-option
              v-for="option in campaignsOptions"
              class="select-default text-uppercase"
              :value="option.value"
              :label="option.label"
              :key="option.value"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-12 col-md-6">
        <base-input
          :label="$t('datatable.phone')"
          v-model="filters.phone"
        />
      </div>
      <div class="col-12 col-md-6">
        <base-input
          :label="$t('datatable.email')"
          v-model="filters.email"
        />
      </div>
      <div class="col-12 col-md-6">
        <base-input :label="$t('datatable.status')">
          <el-select
            clearable
            class="select-default text-uppercase"
            :placeholder="$t('fields.status')"
            v-model="filters.status"
          >
            <el-option
              v-for="option in statusOptions"
              class="select-default text-uppercase"
              :value="option.value"
              :label="option.label"
              :key="option.value"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-12 col-md-6">
        <base-input :label="$t('callcenter.has_deadline')">
          <el-select
            clearable
            class="select-default text-uppercase"
            :placeholder="$t('fields.choose')"
            v-model="filters.hasDeadline"
          >
            <el-option
              v-for="option in [
                  { value: true, label: $t('common.yes')},
                  { value: false, label: $t('common.no')},
                ]"
              class="select-default text-uppercase"
              :value="option.value"
              :label="option.label"
              :key="option.value"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
    </template>
  </octo-table>
</template>

<script>
import OctoTable from "@/components/octo-table/OctoTable";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";
import Badge from "@/components/Badge";
import {Option, Select} from "element-ui";
import {mapGetters} from "vuex";
import LabelThemeComponent from "@/components/LabelThemeComponent";

export default {
  name: "CallCenterLeadDatatable",
  components: {LabelThemeComponent, Badge, OctoIcon, OctoTable, [Select.name]: Select, [Option.name]: Option},
  data() {
    return {
      endpoints: endpoints,
      name: "CallCenterLeadDatatable",
      filters: {
        operatorIds: null,
        status: null
      },
      fields: [
        {prop: 'name', label: 'name', slot: true, align: 'left'},
        {prop: 'callCenterLeadStatus', label: 'call_center_lead_status', slot: true, align: 'center'},
        {prop: 'operator', label: 'operator', slot: true, align: 'center'},
        {prop: 'date', label: 'date', align: 'center', slot: true,},
        {prop: 'actions', label: 'actions', slot: true, align: 'center'},
      ],
      statusOptions: [
        {value: 'free', label: this.$t('callcenter.status.free')},
        {value: 'processing', label: this.$t('callcenter.status.processing')},
        {value: 'public', label: this.$t('callcenter.status.public')},
        {value: 'private', label: this.$t('callcenter.status.private')},
        {value: 'unattainable', label: this.$t('callcenter.status.unattainable')}
      ]
    }
  },

  beforeMount() {
    this.filters = this.getFilters(this.name);
  },
  computed: {
    ...mapGetters({
      getFilters: 'common/datatableFilters',
      campaigns: 'common/campaigns',
      users: 'common/users',
      operators: 'common/operators',
    }),

    campaignsOptions() {
      return this.$_.map(this.campaigns, campaign => {
        return {
          label: campaign.name,
          value: campaign.id
        }
      });
    },

    operatorsOptions() {
      return this.$_.map(this.operators, user => {
        return {
          label: (user?.lastname + ' ' + user?.firstname) || user.username,
          value: user.id
        }
      });
    },
  },

}
</script>

<style scoped>

</style>
